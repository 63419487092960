import React from 'react';
import styled from 'styled-components';

const MainAppContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
`;
const MainContentContainer = styled.div`
    font-size: 16px;
    border: 1px solid black;
    border-radius: 12px;
    padding: 30px;
    line-height: 20px;
    margin-top: 100px;
`;
const MainHeader = styled.div`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 40px;
    border-bottom: 1px solid black;
    padding-bottom: 16px;
`;
const SubHeader = styled.div`
    font-size: 20px;
    margin-top: 16px;
`;
const StyledLine = styled.hr`
    border: 1px solid #dedddd;
    margin-bottom: 16px;
`;
const StyledFooter = styled.div`
    padding-top: 16px;
`;
const StyledBlogLink = styled.a`
    text-decoration: none;
    border-bottom: 1px solid #FED1C8;
    box-shadow: inset 0 -7px 0 #FED1C8;
    color: rgba(15, 35, 59, 0.55);
    transition: all 0.25s ease-in-out;
    padding: 2px 2px 0 2px;
    &:hover {
        background-color: #FED1C8;
    }
`;

const CodeSnippetBox = styled.div`
    background-color: #f1f1f1;
    color: #fe5148;
    font-family: 'Source Code Pro', monospace;
    border-radius: 6px;
    border: 1px solid #dedddd;
    padding: 0px 2px 0px;
    display: inline-block;
    font-size: 14px;
    margin: 0px 4px 0px;
    text-align: justify;
`;
function App() {
    return (
        <MainAppContainer className="main-app">
            <MainContentContainer>
                <MainHeader>linh le kim</MainHeader>
                <p>
                    I'm a software developer based in Montreal. Currently working at Tempo, an Atlassian Ecosystem SaaS
                    company specializing in time tracking tools, I enjoy writing scalable, testable code with an emphasis on UX while maintaining continuous delivery.
                    <SubHeader>my tools</SubHeader>
                    <StyledLine />
                    <CodeSnippetBox>
                        Javascript/ES6, Redux, React, Styled-Components, Typescript, Node.js, Express, Java, Gradle,
                        Postgres, MongoDB, Jest, Enzyme, React-Testing Library, Flask, Webdriver.io,
                        Cypress, AWS, Docker, Kubernetes
                    </CodeSnippetBox>
                    <p>
                        When not at work, I like to dabble in
                        <CodeSnippetBox>Kotlin</CodeSnippetBox> and
                        <CodeSnippetBox>GraphQL</CodeSnippetBox>, but mainly I just like making
                        pretty things that are efficient and modular. Current WIP: exploring my heritage during Covid times at
                        <StyledBlogLink href="http://dalat-linh.surge.sh/">Dalat Linh</StyledBlogLink>
                    </p>
                </p>
                <p>
                    <SubHeader>hobbies</SubHeader>
                    <StyledLine />
                    On my free time, I enjoy cooking 🍳, reading mostly sci-fi books 📚 🐛 (Brandon
                    Sanderson, I love you!), hiking, and cycling 🚲 - if all the stars align. I also
                    enjoy coffee (see my retired <StyledBlogLink href="http://mtlbeanstalk.com">blog</StyledBlogLink>

                    )
                </p>
                <StyledFooter>© 2020 Linh Le Kim</StyledFooter>
            </MainContentContainer>
        </MainAppContainer>
    );
}

export default App;
